import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { decodeUrl, formatURL } from '../utils';
import ReactGA from 'react-ga4';
import axios from 'axios';


const QRCodeRedirect = () => {
    const { qrcodeId } = useParams();

    useEffect(() => {
        if (qrcodeId) {
            ReactGA.send({
                hitType: 'pageview',
                page: `/redirect/${qrcodeId}`,
                title: `Share QRCode Page - QRCode ${qrcodeId}`,
            });

            const getQRCodeDetailsEndpoint = `/api/getQRCodeDetails/${qrcodeId}`;
            axios.get(getQRCodeDetailsEndpoint, { headers: { 'Content-Type': 'application/json' } })
                .then((response) => {
                    if (response.data.status) {
                        setTimeout(() => {
                            window.location.href = formatURL(response.data.result[0]?.qrcode_url);
                        }, 100); // Adjust the delay as necessary
                    } else {
                        setTimeout(() => {
                            window.location.href = "/home";
                        }, 100);
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                    setTimeout(() => {
                        window.location.href = "/home";
                    }, 100);
                });
        }
    }, [qrcodeId]);



    return (
        <div className="redirect-layout app-center">
            <div className="loader"></div>
        </div>
    );
};

export default QRCodeRedirect;
