import React, { useState, useEffect } from 'react';
import './css/ReportWithEventCountByEventName.css';
import EventCountByEventNameChart from './chart/EventCountByEventNameChart';
import DateFilter from './DateFilter';

const ReportWithEventCountByEventName = ({ reportWithEventCountByEventName }) => {
  const [filteredData, setFilteredData] = useState(reportWithEventCountByEventName);

  return (
    <>
      {reportWithEventCountByEventName && reportWithEventCountByEventName?.length > 0 && <div className="event-count-table card-container">
        <h6 className="text-left text-background" >*Below graphs show data that is atleast 24 hours old.</h6>
        <div className="filter-flex">
          <h4>Event count by <b>Event name</b> <small>(Display activity reports from the start date up to 24 hours prior.)</small></h4>
          <DateFilter data={reportWithEventCountByEventName} onFilter={setFilteredData} />
        </div>
        <EventCountByEventNameChart data={filteredData} />
      </div>}
    </>
  );
};

export default ReportWithEventCountByEventName;