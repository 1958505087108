import React from 'react';
import { FaCamera } from 'react-icons/fa';
import './css/LinkTreeCustomerProfile.css'; // Import CSS file for styling
import API from '../config/env';

const LinkTreeCustomerProfile = ({ selectedImage, setSelectedImage, preview, setPreview, handleGenerateAndShareClick, isEdit }) => {

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file?.type?.startsWith("image/")) {
      setPreview(URL.createObjectURL(file));
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          canvas.width = 256;
          canvas.height = 256;

          // Calculate scaling and position
          const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
          const x = (canvas.width - img.width * scale) / 2; // Center horizontally
          const y = (canvas.height - img.height * scale) / 2; // Center vertically

          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, x, y, img.width * scale, img.height * scale);

          // Convert canvas to a Blob or data URL
          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, { type: file.type });
            setSelectedImage(resizedFile);
          }, file.type);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid image file.");
      e.target.value = ""; // Clear the input
    }
  };

  return (
    <>
      {
        isEdit ? <div className="image-upload-container">
          <div className="image-upload-wrapper" onClick={() => document.getElementById('profile-input').click()}>
            <input
              id="profile-input"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleImageChange}
            />
            <div className="image-preview">
              {preview ? (
                <img src={preview?.includes("blob") ? preview : `${API.WEBSITE_URL}/${preview}`} alt="Selected" className="image-preview-round" />
              ) : (
                <div className="image-placeholder">
                  <FaCamera size={40} />
                </div>
              )}
              <div className="camera-icon-overlay">
                <FaCamera size={20} />
              </div>
            </div>
          </div>
          <button className="custom-button" onClick={handleGenerateAndShareClick} disabled={!selectedImage}>
            Submit
          </button>
        </div> : <div className="image-upload-container">
          <div className="image-upload-wrapper">
            <div className="image-preview">
              {preview ? (
                <img src={`${API.WEBSITE_URL}/${preview}`} alt="Selected" className="image-preview-round" />
              ) : (
                <div className="image-placeholder">
                  <FaCamera size={40} />
                </div>
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default LinkTreeCustomerProfile;
