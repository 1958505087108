import React, { useEffect, useState } from "react";
import "./style.css";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { toast } from "react-toastify";
import { reactLocalStorage } from "reactjs-localstorage";


function AccountStatus() {
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
      getLocalStorageInfo();
  }, []);

  const getLocalStorageInfo = async () => {
      try {
          let localData = reactLocalStorage.getObject("user");
          if (Object.keys(localData).length != 0) {
              setUserInfo(localData)
          }
      } catch (e) {
          console.log(e);
      }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    var result = window.confirm("Are you sure Want to delete Account?");
    if (result) {
      setIsLoading(true);
      fetch('/api/deleteAccount', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: userInfo?.id,
            email: userInfo?.email,
            unique_id: userInfo?.unique_id,
          }),
      }).then((response) => response.json())
      .then((response) => {
          if (response.status) {
            setIsLoading(false);
            toast.error("Account Deleted Successfully!!", {
              position: "top-right",
              autoClose: 6000
            });
            setTimeout(() => {
              reactLocalStorage.remove("user");
              window.location.href = "/login";
            }, 2000)
          }
      }).catch((error) => {
          setIsLoading(false);
          console.log("error", error);
          toast.error("Something went wrong try again later!", {
              position: "top-right",
              autoClose: 2000
          });
      });
    }
  };

  return (
    <Header>
      {isLoading && <div class="whole-loading">Loading&#8230;</div>}
      <div className="app-center mt-5">
        <div className="container-fluid align-items-center justify-content-md-center mt-5">
          <div className="row align-items-center justify-content-md-center mt-5">
            <div className="col-1"></div>
            <div className="col-8 mx-auto mt-5 text-center">
              <div className="mt-5">
                <h1 className="mt-5">Account Status</h1>
                <hr />
                <h2 className="profile-email">{userInfo?.email}</h2>
                <form onSubmit={handleSubmit} className="pt-5">
                  <h4>When the user deactivates their account, all associated data, including QR codes, link trees, and Google monitoring data, will be permanently deleted to ensure privacy and data integrity.</h4>
                  <button type="submit" className={`mt-5 mb-5 btn red`}>Delete Account</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Header>
  );
}

export default AccountStatus;
