import React, { useState, useEffect } from 'react';
import './css/style.css';
import DateFilter from './DateFilter';
import PlatformOverviewChart from './chart/PlatformOverviewChart';

const ReportWithPlatformOverview = ({ reportWithPlatformOverview }) => {

  return (
    <>
      {reportWithPlatformOverview && reportWithPlatformOverview?.length > 0 && <div className="event-count-table">
        <h6 className="text-left text-background" >*Below graphs show data that is atleast 24 hours old.</h6>
        <div className="filter-flex">
          <h4>Report With <b>Platform Overview</b> <small>(Display activity reports from the start date up to 24 hours prior.)</small></h4>
        </div>
        <PlatformOverviewChart data={reportWithPlatformOverview} />
      </div>}
    </>
  );
};

export default ReportWithPlatformOverview;