import React, { useState } from 'react';
import './css/QRCodeTable.css';

import NotFound from '../images/notfound.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import API from '../config/env';
import { FaInfoCircle } from "react-icons/fa";
import moment from 'moment';
import { checkIsNull, formatURL } from '../utils';
import { QRCodeSVG } from 'qrcode.react';
import { Tooltip as ReactTooltip } from 'react-tooltip'

const LinksTreeTable = ({ data, userInfo, isDashboard = false }) => {
    const navigate = useNavigate();
    const [filteredItems, setFilteredItems] = useState(data);
    const [isLoading, setIsLoading] = useState(false);

    const navigateViewPage = (qrcode) => {
        navigate(`/links-tree/${qrcode?.qrcode_id}`, { state: { ...qrcode } });
    };

    const navigateEditPage = (qrcode) => {
        navigate(`/edit/${qrcode?.qrcode_id}`, { state: { ...qrcode } });
    };

    const navigateToSharePage = (qrcodeId) => {
        navigate(`/share/${qrcodeId}`);
        window.location.reload();
    };

    const navigateToAnalyticsView = (record) => {
        navigate(`/analytics/${record?.qrcode_type}/${record?.qrcode_url?.split('/').pop()}`);
        window.location.reload();
    };

    const deleteQRCode = (record) => {
        var result = window.confirm("Are you sure Want to delete Links Tree?");
        if (result) {
            axios
                .post(`/api/deleteQRCode`, {
                    user_id: userInfo.id,
                    id: record?.id
                },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                .then(function (response) {
                    if (response.data && response.data.status) {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 2500,
                        });
                        setTimeout(() => {
                            window.location.reload(true);
                        }, 2000)
                    } else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 2500,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                    toast.error('Something went wrong! Please Try Again later', {
                        position: "top-right",
                        autoClose: 2500,
                    });
                });
        }
    };

    const handleIsPrivateChange = async (record) => {
        const confirmMessage = record?.is_private
            ? "Are you sure you want to make this Links Tree public?"
            : "Are you sure you want to make this Links Tree private?";
        const result = window.confirm(confirmMessage);

        if (result) {
            try {
                setIsLoading(true);

                fetch("/api/updateQRCodesPrivate", {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        id: record?.id,
                        qrcode_id: record?.qrcode_id,
                        unique_id: userInfo?.unique_id,
                        is_private: !record.is_private,
                    }),
                }).then((response) => response.json())
                    .then((response) => {
                        setIsLoading(false);
                        if (response.status) {
                            toast.success(
                                record?.is_private
                                    ? "Links Tree marked as public successfully!"
                                    : "Links Tree marked as private successfully!",
                                {
                                    position: "top-right",
                                    autoClose: 2000,
                                }
                            );
                            record.is_private = !record.is_private;
                        }
                    }).catch((error) => {
                        console.log("error", error);
                        setIsLoading(false);
                        toast.error("Something went wrong try again later!", {
                            position: "top-right",
                            autoClose: 2000
                        });
                    });
            } catch (error) {
                console.error("Error:", error);
                setIsLoading(false);
                toast.error("Something went wrong. Please try again later.", {
                    position: "top-right",
                    autoClose: 2000,
                });
            }
        }
    };

    return (
        <div className="search-container">
            {isLoading && <div class="whole-loading">Loading&#8230;</div>}
            <div className="qrcode-table qrcode-home-table">
                <table className="table-ui">
                    {(userInfo && userInfo?.id && isDashboard) && <thead>
                        <tr>
                            <th>Image</th>
                            <th>Business Name</th>
                            <th>URL</th>
                            <th>Creation Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>}
                    <tbody>
                        {(filteredItems && filteredItems?.length > 0) ? <>
                            {filteredItems.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <div
                                            className="qr-image"
                                            onClick={() => {
                                                window.open(formatURL(item?.qrcode_url), '_blank', 'noopener,noreferrer');
                                            }}>
                                            <QRCodeSVG
                                                value={item?.qrcode_url}
                                                size={128}
                                                imageSettings={{
                                                    src: checkIsNull(item?.qrcode_logo) ? `${API.WEBSITE_URL}/${item?.qrcode_logo}` : null,
                                                    excavate: checkIsNull(item?.qrcode_logo) ? true : false,
                                                    height: checkIsNull(item?.qrcode_logo) ? 16 : 0,
                                                    width: checkIsNull(item?.qrcode_logo) ? 16 : 0,
                                                }}
                                                fgColor={item?.qrcode_color ? item?.qrcode_color : "#000"}
                                                className="grid-image"
                                            />
                                            <small className={item.qrcode_type === "linktree" ? "linktree-tag" : "qrcode-tag"}>
                                                <b>{item.qrcode_type === "linktree" ? "Links Tree" : "QR Code"}</b>
                                            </small>
                                        </div>
                                    </td>
                                    <td translate="no"><strong translate="no">{item?.business_name}</strong></td>
                                    <td>
                                        <a href={item.qrcode_url} target="_blank" rel="noopener noreferrer">
                                            {item.qrcode_url}
                                        </a>
                                    </td>
                                    <td>{moment(item.date_created).format('DD-MM-YYYY')}</td>
                                    <td style={{ width: "160px" }}>
                                        <div className="private-layout">
                                            <div className="form-group" style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}>
                                                <label class="col-form-label" style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
                                                    <div style={{ display: 'flex', fontWeight: 'bold' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={item?.is_private}
                                                            onChange={() => handleIsPrivateChange(item)}
                                                            style={{ marginRight: "8px" }}
                                                            disabled={isLoading}
                                                        />
                                                        Make Private{" "}
                                                    </div>
                                                </label>
                                                <span
                                                    data-tooltip-id="private-tooltip"
                                                    style={{
                                                        marginLeft: "12px",
                                                        display: "inline-block",
                                                        cursor: "pointer",
                                                        marginTop: 'calc(.775rem + 1px)',
                                                    }}
                                                >
                                                    <FaInfoCircle />
                                                </span>
                                                <ReactTooltip
                                                    id="private-tooltip"
                                                    place="top"
                                                    variant="info"
                                                    content="If selected, your Links Tree will not show on the homepage. It will not show up in search results."
                                                />
                                            </div>
                                            <li className="nav-item dropdown qrcode-dropdown">
                                                <a
                                                    className="nav-link dropdown-toggle qrcode-nav-link"
                                                    href="#"
                                                    id="navbarDropdown"
                                                    role="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    Action
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <li>
                                                        <a className="dropdown-item"
                                                            href="javascript:void(0)"
                                                            onClick={() => navigateToSharePage(item?.qrcode_id)}>Share</a>
                                                    </li>
                                                    {(userInfo && userInfo?.id && isDashboard) && <>
                                                        <li>
                                                            <a className="dropdown-item"
                                                                href="javascript:void(0)"
                                                                onClick={() => navigateEditPage(item)}>Edit</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item"
                                                                href="javascript:void(0)"
                                                                onClick={() => navigateViewPage(item)}>View / Add / Delete Links</a>
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item"
                                                                href="javascript:void(0)"
                                                                onClick={() => navigateToAnalyticsView(item)}>View Analytics</a>
                                                        </li>
                                                        <li>
                                                            <hr className="dropdown-divider" />
                                                        </li>
                                                        <li>
                                                            <a className="dropdown-item delete-dropdown-item"
                                                                href="javascript:void(0)"
                                                                onClick={() => deleteQRCode(item)}>Delete</a>
                                                        </li>
                                                    </>}
                                                </ul>
                                            </li>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </> : <>
                            <div className="mt-3 text-center">
                                <img src={NotFound} className="notFound" />
                                <h4>No Result Record Found!</h4>
                            </div>
                        </>}

                    </tbody>
                </table>
            </div>

        </div>
    );
};

export default LinksTreeTable;
