import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const PlatformOverviewChart = ({ data }) => {
  if (!data || !Array.isArray(data)) {
    return <div>No data available to display the chart.</div>;
  }
  const transformedData = data?.map(item => ({
    label: item.dimensionValues[1].value, // Taking the second index as type
    value: parseInt(item.metricValues[0].value) // Taking the first metric value and converting to integer
  }));
  const chartData = {
    labels: transformedData?.map(item => item.label),
    datasets: [
        {
            data: transformedData?.map(item => item.value),
            backgroundColor: ['#FF6384', '#36A2EB'], // Colors for slices
            hoverBackgroundColor: ['#FF6384', '#36A2EB'],
        },
    ],
};

  return (
    <div className="chart-container">
      <Doughnut
        data={chartData}
        style={{
          display: 'inline',
          height: '100% !important'
        }}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Device Distribution',
            },
          }
        }}
      />
    </div>
  );
};

export default PlatformOverviewChart;
