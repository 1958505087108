import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import '../styles/LinkTree.css';
import { checkIsNull, decodeUrl, encodeUrl, formatURL, getLogoByTitle } from '../utils';
import { Logos } from '../utils/mock';
import Header from '../components/Header';
import { reactLocalStorage } from "reactjs-localstorage";
import API from '../config/env';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import LinkTreeCustomerProfile from '../components/LinkTreeCustomerProfile';
import LinkTreeCustomerFeaturedProfile from '../components/LinkTreeCustomerFeaturedProfile';
import Footer from '../components/Footer';


// Custom Option component to display image and title in the dropdown
const customOption = (props) => (
    <div {...props.innerProps} className="customOption" style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
        <img src={props.data.image} alt={props.data.title} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
        <span>{props.data.title}</span>
    </div>
);

function LinkTree() {
    const { state } = useLocation();
    const { id, qrcode_url, qrcode_type, qrcode_logo, featured_logo, qrcode_color } = state;

    const { qrcodeId } = useParams();
    const decodedUrl = decodeUrl(qrcodeId);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [items, setItems] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState({ title: '', url: '', description: '' });
    // Map the data to fit react-select's options structure
    const options = Logos.map(item => ({
        value: item.id,
        label: item.title,
        image: item.image,
        title: item.title,
    }));
    const qrCodeUrl = qrcode_url ? qrcode_url : decodedUrl;
    const [selectedImage, setSelectedImage] = useState(null);
    const [preview, setPreview] = useState(checkIsNull(qrcode_logo) ? qrcode_logo : null);
    const [selectedFeaturedImage, setSelectedFeaturedImage] = useState(null);
    const [previewFeatured, setPreviewFeatured] = useState(checkIsNull(featured_logo) ? featured_logo : null);
    const svgRef = useRef(null);

    useEffect(() => {
        getLocalStorageInfo();
        if (qrcodeId) {
            getLinkTree(qrcodeId);
        }
    }, [qrcodeId]);

    const getLocalStorageInfo = async () => {
        try {
            let localData = reactLocalStorage.getObject("user");
            if (Object.keys(localData).length != 0) {
                setUserInfo(localData)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const getLinkTree = async () => {
        try {
            const getLinkTreeEndpoint = `/api/getLinkTree/${qrcodeId}`;
            axios.get(getLinkTreeEndpoint, { headers: { 'Content-Type': 'application/json' } })
                .then((response) => {
                    if (response.data.status) {
                        setItems(response.data.result);
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const navigateGenerateAndShareClick = async () => {
        navigate(`/share/${qrcodeId}`)
    }

    const handleGenerateAndShareClick = async (event) => {
        event.preventDefault();
        if (qrCodeUrl) {
            setIsLoading(true);

            const formData = new FormData();
            formData.append('id', id);
            formData.append('user_id', userInfo.id);
            formData.append('qrcode_id', qrcodeId);
            formData.append('unique_id', userInfo?.unique_id);
            formData.append('qrcode_url', qrCodeUrl);
            checkIsNull(selectedImage) && formData.append('logo', selectedImage);
            checkIsNull(selectedFeaturedImage) && formData.append('featured_logo', selectedFeaturedImage);
            formData.append('qrcode_color', qrcode_color);

            fetch('/api/updateQRCodes', {
                method: 'POST',
                body: formData,
            }).then((response) => {
                toast.success("Image Upload for Link Tree Successfully!", {
                    position: "top-right",
                    autoClose: 2000
                });
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.log("error", error);
                toast.error("Something went wrong try again later!", {
                    position: "top-right",
                    autoClose: 2000
                });
            });
        } else {
            toast.error("Please Verify URL!", {
                position: "top-right",
                autoClose: 6000
            });
        }
    };

    const handleAddClick = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setSelectedItem(null);
        setFormData({ title: '', url: '', description: '' });
    };

    const handleSelectChange = (selected) => {
        setSelectedItem(selected);
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        console.log("userInfo", userInfo)
        e.preventDefault();
        if (selectedItem && selectedItem?.title != null) {
            setIsLoading(true);
            let parmas = {
                ...formData,
                logo: selectedItem?.title,
                user_id: userInfo?.id,
                unique_id: qrcodeId
            }
            const addLinkTreeEndpoint = "/api/addLinkTree";
            console.log("addLinkTreeEndpoint", addLinkTreeEndpoint);
            axios.post(addLinkTreeEndpoint, parmas, { headers: { 'Content-Type': 'application/json' } })
                .then((response) => {
                    console.log("response addLinkTree", response.data);
                    if (response.data.status) {
                        setIsLoading(false);
                        toast.success("Link Added to Tree Successfully!", {
                            position: "top-right",
                            autoClose: 2000
                        });
                        // setItems((prevItems) => [...prevItems, formData]);
                        getLinkTree(userInfo.id);
                        handleClosePopup();
                    } else {
                        setIsLoading(false);
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 2000
                        });
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                    setIsLoading(false);
                    toast.error("Something went wrong try again later!", {
                        position: "top-right",
                        autoClose: 2000
                    });
                });
        } else {
            toast.error("Please Select Logo Type!", {
                position: "top-right",
                autoClose: 2000
            });
        }
    };

    const deleteLink = (record) => {
        var result = window.confirm("Are you sure Want to delete Link?");
        if (result) {
            axios
                .post(`/api/deleteLink`, {
                    user_id: userInfo.id,
                    id: record?.id
                },
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                .then(function (response) {
                    if (response.data && response.data.status) {
                        toast.success(response.data.message, {
                            position: "top-right",
                            autoClose: 2500,
                        });
                        getLinkTree(userInfo.id);
                    } else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 2500,
                        });
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                    toast.error('Something went wrong! Please Try Again later', {
                        position: "top-right",
                        autoClose: 2500,
                    });
                });
        }
    };

    const Timeline = ({ items }) => {
        return (
            <div className="timeline-container">
                <div className="link-tree-customer-profile">
                    <LinkTreeCustomerProfile selectedImage={selectedImage} setSelectedImage={setSelectedImage} preview={preview} setPreview={setPreview} handleGenerateAndShareClick={handleGenerateAndShareClick} isEdit={true} />
                </div>
                <div className="timeline">
                    {items.map((item, index) => (
                        <div key={index} className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                            <div className="timeline-content">
                                <a href={(item?.logo || item?.title) == "WhatsApp" ? `https://wa.me/${item.url.trim()}` :
                                    (item?.logo || item?.title) == "Contact" ? `tel:${item.url.trim()}` : formatURL(item.url.trim())}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <div className="timeline-items">
                                        <div className="circle">{getLogoByTitle((item?.logo || item?.title)) != null ? <img src={getLogoByTitle((item?.logo || item?.title))} alt={(item?.logo || item?.title)} className='circle-logo' /> : index + 1}</div>
                                        <div className="item-content">
                                            <h3 translate="no">{item.title}</h3>
                                            <p translate="no">{item.description}</p>
                                        </div>
                                    </div>
                                </a>
                                <button onClick={() => deleteLink(item)} className={`timeline-button ${index % 2 === 0 ? 'timeline-start' : 'timeline-end'}`}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <Header>
            {isLoading && <div class="whole-loading">Loading&#8230;</div>}
            <div className="linkTree layout-center">
                <div className="link-tree-customer-featured-profile">
                    <LinkTreeCustomerFeaturedProfile 
                        selectedFeaturedImage={selectedFeaturedImage} 
                        setSelectedFeaturedImage={setSelectedFeaturedImage} 
                        previewFeatured={previewFeatured} 
                        setPreviewFeatured={setPreviewFeatured} 
                        handleGenerateAndShareClick={handleGenerateAndShareClick} 
                        isEdit={true} 
                    />
                </div>
                {/* <h4>Add own social media and other URL </h4> */}
                <Timeline items={items} />
                <div ref={svgRef} className="d-none">
                    {qrCodeUrl && <QRCodeSVG
                        value={qrCodeUrl}
                        size={256}
                        imageSettings={{
                            src: userInfo?.image ? userInfo?.image : null,
                            excavate: userInfo?.image ? true : false,
                            height: userInfo?.image ? 40 : 0,
                            width: userInfo?.image ? 40 : 0,
                        }}
                        fgColor={"#000"}
                    />}
                </div>
                <button className="share-floating-button" onClick={navigateGenerateAndShareClick}>Generate & Share</button>
                <button className="floating-button" onClick={handleAddClick}>Add Link</button>
                {showPopup && (
                    <div className="popup">
                        <div className="popup-content">
                            <h2 className="popup-title">Add New URL</h2>
                            <hr />
                            <form onSubmit={handleSubmit}>
                                <label>
                                    Logo <span class="mandatory">*</span>:
                                    <Select
                                        options={options}
                                        onChange={handleSelectChange}
                                        value={selectedItem}
                                        placeholder="Select an option"
                                        className="link-logo-select"
                                        required
                                        components={{
                                            Option: customOption,
                                            SingleValue: ({ data }) => (
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <img src={data.image} alt={data.title} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
                                                    {data.title}
                                                </div>
                                            ),
                                        }}
                                    />
                                </label>
                                <label>
                                    Title <span class="mandatory">*</span>:
                                    <input
                                        type="text"
                                        placeholder={"Enter Title"}
                                        name="title"
                                        value={formData.title}
                                        onChange={handleFormChange}
                                        required
                                    />
                                </label>
                                <label>
                                    <span>{(selectedItem?.title == "WhatsApp" || selectedItem?.title == "Contact") ? "Number" : "URL"}</span> <span class="mandatory">*</span>:
                                    <input
                                        type={(selectedItem?.title == "WhatsApp" || selectedItem?.title == "Contact") ? "number" : "text"}
                                        placeholder={(selectedItem?.title == "WhatsApp" || selectedItem?.title == "Contact") ? "Enter Mobile number" : "Enter URL"}
                                        name="url"
                                        value={formData.url}
                                        onChange={handleFormChange}
                                        required
                                    />
                                    <br />

                                </label>
                                <label>
                                    Description:
                                    <textarea
                                        name="description"
                                        maxLength={255}
                                        value={formData.description}
                                        onChange={handleFormChange}
                                    />
                                </label>
                                <div className="popup-button">
                                    <button type="button" disabled={isLoading} onClick={handleClosePopup}>Cancel</button>
                                    <button type="submit" disabled={isLoading} className="green">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </Header>
    );
}

export default LinkTree;
