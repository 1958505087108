import React, { useState } from 'react';
import './css/SearchResult.css';

import NotFound from '../images/notfound.png';
import { useNavigate } from 'react-router-dom';
import API from '../config/env';
import { FaTimes } from 'react-icons/fa';
import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share';
import { checkIsNull, formatURL } from '../utils';
import { QRCodeSVG } from 'qrcode.react';

const SearchResult = ({ data }) => {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [filteredItems, setFilteredItems] = useState(data);

    const handleCopyToClipBoard = (qrcodeId) => {
        const textToCopy = `${API.WEBSITE_URL}/redirect/${qrcodeId}`;
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => alert("Copied to clipboard!"))
                .catch((err) => console.error("Failed to copy text: ", err));
        } else {
            // Fallback for non-HTTPS environments
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            textArea.style.position = "fixed"; // Avoid scrolling to bottom of the page in some cases
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                document.execCommand("copy");
                alert("Copied to clipboard!");
            } catch (err) {
                console.error("Fallback: Failed to copy text: ", err);
            }

            document.body.removeChild(textArea);
        }

    };

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearchText(value);
        setFilteredItems(
            data?.filter((item) =>
                item?.business_name?.toLowerCase().includes(value.toLowerCase()) ||
                item?.business_name?.toLowerCase().includes(value.toLowerCase()) ||
                item?.unique_id?.toLowerCase().includes(value.toLowerCase()) ||
                item?.qrcode_type?.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const advancedSearch = (e) => {
        const value = e.target.value;
        const lowerCaseValue = value.toLowerCase();
    
        setSearchText(value);
    
        setFilteredItems(
            data?.filter((item) => {
                const fieldsToSearch = [
                    item?.business_name,
                    item?.unique_id,
                    item?.qrcode_type,
                ];
    
                // Combine all fields into a single string without spaces
                const concatenatedFields = fieldsToSearch
                    .filter(Boolean) // Exclude null/undefined fields
                    .map((field) => field.toLowerCase().replace(/\s+/g, "")) // Remove spaces
                    .join(" ");
    
                // Check if query matches any field or concatenated version
                return fieldsToSearch.some((field) => {
                    if (!field) return false;
    
                    const lowerCaseField = field.toLowerCase();
                    return (
                        lowerCaseField.includes(lowerCaseValue) || // Substring match
                        concatenatedFields.includes(lowerCaseValue) // Concatenated match
                    );
                });
            })
        );
    };
    

    const clearSearch = () => {
        setSearchText('');
        setFilteredItems(data);
    };

    // Function to handle share action
    const handleToSharePage = async (qrcodeId) => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share - get business up with DeQRGids',
                    text: 'Generate a custom QR code instantly to share any link and download your unique QR code to share online.',
                    url: `${API.WEBSITE_URL}/redirect/${qrcodeId}`,
                });
                console.log("Image shared successfully");
            } catch (error) {
                console.error("Error sharing:", error);
            }
        } else if (navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(`${API.WEBSITE_URL}/redirect/${qrcodeId}`);
                alert("URL copied to clipboard! You can now share it manually.");
            } catch (error) {
                const shareUrl = `${API.WEBSITE_URL}/redirect/${qrcodeId}`;
                alert(`Sharing is not supported in your browser. Please copy the URL to share: ${shareUrl}`);
            }
        } else {
            const shareUrl = `${API.WEBSITE_URL}/redirect/${qrcodeId}`;
            alert(`Sharing is not supported in your browser. Please copy the URL to share: ${shareUrl}`);
        }
    };

    const navigateToSharePage = (qrcodeId) => {
        navigate(`/share/${qrcodeId}`);
        window.location.reload();
    };

    return (
        <div className="search-container search-style">
            <div class="jobs__banner mt-5">
                <h1 class="homeSearch__tagline text-left">Find QR Codes and QR Links Tree...</h1>
                <div className="search-box search-input-container">
                    <input
                        type="text"
                        placeholder="Enter company name..."
                        value={searchText}
                        onChange={advancedSearch}
                        className="search-input"
                    />
                    <button onClick={advancedSearch} className="search-button">
                        Search
                    </button>
                    {searchText && (
                        <FaTimes onClick={clearSearch} className="clear-icon" />
                    )}
                </div>
            </div>
            <div className="qrcode-search-table">
                <h4 className="text-left" >Recently Created QRCode</h4>
                <hr />
                {(filteredItems && filteredItems?.length > 0) ? (
                    <div className="grid-ui">
                        {filteredItems.map((item, index) => (
                            <div key={index} className="grid-item">
                                <div className="grid-image-layout">
                                    <div 
                                        className="qr-image" 
                                        onClick={() => {
                                            const redirectURL = `${API.WEBSITE_URL}/redirect/${item?.qrcode_id}`
                                            window.open(formatURL(redirectURL), '_blank', 'noopener,noreferrer');
                                        }}>
                                        <QRCodeSVG 
                                            value={`${API.WEBSITE_URL}/redirect/${item.qrcode_id}`}
                                            size={256} 
                                            imageSettings={{
                                                src: checkIsNull(item?.qrcode_logo) ? `${API.WEBSITE_URL}/${item?.qrcode_logo}` : null,
                                                excavate: checkIsNull(item?.qrcode_logo) ? true : false,
                                                height: checkIsNull(item?.qrcode_logo) ? 40 : 0,
                                                width: checkIsNull(item?.qrcode_logo) ? 40 : 0,
                                            }}
                                            fgColor={item?.qrcode_color ? item?.qrcode_color : "#000"}
                                            className="grid-image"
                                        /> 
                                    </div>
                                    <small className={`${item.qrcode_type === "linktree" ? "linktree-tag" : "qrcode-tag"} grid-type`}>
                                        <b>{item.qrcode_type === "linktree" ? "Links Tree" : "QR Code"}</b>
                                    </small>
                                </div>
                                <h4 className="business-name" translate="no">{item?.business_name}</h4>
                                <p className="grid-date">
                                    <div>
                                        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${API.WEBSITE_URL}/redirect/${item.qrcode_id}&title=Share - get business up with DeQRGids&summary=Generate a custom QR code instantly to share any link and download your unique QR code to share online.?utm_source=linkedin&utm_medium=social&utm_campaign=linkedin_social_vac_share`} target="_blank" style={{ marginRight: '8px' }}>
                                            <LinkedinIcon size={32} round={true} />
                                        </a>
                                        <a href={`https://www.facebook.com/sharer/sharer.php?u=${API.WEBSITE_URL}/redirect/${item.qrcode_id}?utm_source=facebook&utm_medium=social&utm_campaign=facebook_social_vac_share`} target="_blank" style={{ marginRight: '8px' }}>
                                            <FacebookIcon size={32} round={true} />
                                        </a>
                                        <a href={`https://twitter.com/intent/tweet?url=${API.WEBSITE_URL}/redirect/${item.qrcode_id}?utm_source=twitter&utm_medium=social&utm_campaign=twitter_social_vac_share&utm_term=business&text=Share - get business up with DeQRGids`} target="_blank" style={{ marginRight: '8px' }}>
                                            <TwitterIcon size={32} round={true} />
                                        </a>
                                    </div>
                                </p>
                                <div className="grid-btn">
                                    <button
                                        className="share-button green m-1"
                                        onClick={() => handleCopyToClipBoard(item?.qrcode_id)}>
                                        Clipboard
                                    </button>
                                    <button
                                        onClick={() => handleToSharePage(item?.qrcode_id)}
                                        className="share-button m-1">
                                        Share
                                    </button>
                                    <button
                                        onClick={() => navigateToSharePage(item?.qrcode_id)}
                                        className="share-button purple m-1">
                                        View more
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="grid-no-result">
                        <div className="no-results">
                            <img src={NotFound} className="notFound" alt="No Results Found" />
                            <h4>No Result Record Found!</h4>
                        </div>
                    </div>
                )}

            </div>

        </div>
    );
};

export default SearchResult;
