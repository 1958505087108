import React, { useEffect, useState } from 'react';
import NotFound from '../images/notfound.png';
import './Dashboard.css';
import Header from '../components/Header';
import { reactLocalStorage } from 'reactjs-localstorage';
import QRCodeTable from '../components/QRCodeTable';
import axios from 'axios';
import Footer from '../components/Footer';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allQRCodes, setAllQRCodes] = useState([]);

  useEffect(() => {
    getLocalStorageInfo();
  }, []);

  const getLocalStorageInfo = async () => {
    try {
      let localData = reactLocalStorage.getObject("user");
      if (Object.keys(localData).length != 0) {
        setUserInfo(localData);
        getAllQRCodes(localData.id);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getAllQRCodes = async (user_id) => {
    try {
      setIsLoading(true);
      const getAllQRCodesEndpoint = `/api/getAllQRCodes/${user_id}`;
      axios.get(getAllQRCodesEndpoint, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          if (response.data.status) {
            setAllQRCodes(response?.data?.result);
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log("error", error);
          setIsLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const createQRCode = () => {
    navigate(`/create`);
    window.location.reload();
  }

  return (
    <Header>
      <div className="layout-center">
        <h1> QR Codes Dashboard </h1>
        <button className="parent-qrcode-button" onClick={createQRCode}>Create QR Code</button>
        {
          isLoading ?
            <div class="whole-loading">Loading&#8230;</div> :
            <>
              {(allQRCodes && allQRCodes?.length > 0) ? <>
                <QRCodeTable data={allQRCodes} userInfo={userInfo} isDashboard={true} />
              </> : <>
                <div className="mt-3 text-center">
                  <img src={NotFound} className="notFound" />
                  <h4>No QRCode and Links Tree Found!</h4>
                </div>
              </>}
            </>
        }
      </div>
      <Footer />
    </Header>
  );
};

export default Dashboard;