import React from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import './css/BatchReport.css';
import countries from "../utils/countries.json"

const BatchReport = ({ batchReport }) => {
  // Extract active countries and their active user count
  const activeCountries = batchReport?.reduce((acc, row) => {
    const country = row.dimensionValues[0]?.value; // Country name
    const activeUsers = parseInt(row.metricValues[0]?.value, 10) || 0;
    acc[country] = (acc[country] || 0) + activeUsers; // Accumulate users per country
    return acc;
  }, {});

  return (
    <div className="batch-report">
      <h6 className="text-left text-background">*Below graphs show data that is atleast 24 hours old.</h6>
      <h4>Active users by <b>Country</b> <small>(Display activity reports from the start date up to 24 hours prior.)</small></h4>
      <div className="map-and-table">
        <div className="map-container">
          <ComposableMap projectionConfig={{ scale: 200 }}>
            <Geographies geography={countries}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  // Check if the country is in the activeCountries list
                  const countryName = geo?.properties?.name;
                  const isActive = activeCountries[countryName] > 0;

                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={isActive ? "#3b82f6" : "#e0e0e0"}
                      stroke="#ffffff"
                      style={{
                        default: { outline: "none" },
                        hover: { outline: "none", fill: isActive ? "#2a6bb5" : "#cccccc" },
                        pressed: { outline: "none" },
                      }}
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
        </div>
        <table className="center-table">
          <thead>
            <tr>
              <th>Country</th>
              <th>Region</th>
              <th>City</th>
              <th>Active Users</th>
            </tr>
          </thead>
          <tbody>
            {batchReport.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{row.dimensionValues[0]?.value || 'N/A'}</td> {/* Country */}
                <td>{row.dimensionValues[1]?.value || 'N/A'}</td> {/* Region */}
                <td>{row.dimensionValues[2]?.value || 'N/A'}</td> {/* City */}
                <td>{row.metricValues[0]?.value || '0'}</td> {/* Active Users */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BatchReport;
