import React, { useState, useEffect } from 'react';
import './css/style.css';
import DateFilter from './DateFilter';
import LanguageOverviewChart from './chart/LanguageOverviewChart';
import LanguageOverviewChartTable from './chart/LanguageOverviewChartTable';

const ReportWithLanguageOverview = ({ reportWithLanguageOverview }) => {
  const [filteredData, setFilteredData] = useState(reportWithLanguageOverview);

  return (
    <>
      {reportWithLanguageOverview && reportWithLanguageOverview?.length > 0 && <div className="event-count-table card-container">
        <h6 className="text-left text-background" >*Below graphs show data that is atleast 24 hours old.</h6>
        <div className="filter-flex">
          <h4>Report With <b>Language Overview</b> <small>(Display activity reports from the start date up to 24 hours prior.)</small></h4>
          <DateFilter data={reportWithLanguageOverview} onFilter={setFilteredData} />
        </div>
        <LanguageOverviewChart data={filteredData} />
        <LanguageOverviewChartTable data={filteredData} />
      </div>}
    </>
  );
};

export default ReportWithLanguageOverview;