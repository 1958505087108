import React, { useState } from "react";
import Cropper from "react-easy-crop";
import { FaCamera } from "react-icons/fa";
import "./css/LinkTreeCustomerFeaturedProfile.css";
import API from "../config/env";

const LinkTreeCustomerFeaturedProfile = ({
  selectedFeaturedImage,
  setSelectedFeaturedImage,
  previewFeatured,
  setPreviewFeatured,
  handleGenerateAndShareClick,
  isEdit,
}) => {
  const [imageFile, setImageFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [aspect, setAspect] = useState(1200 / 420); // Desktop: window.innerWidth > 768 ? 1200 / 420 : 4 / 5

  // Update aspect ratio on resize
  const updateAspectRatio = () => {
    setAspect(1200 / 420);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateAspectRatio);
    return () => window.removeEventListener("resize", updateAspectRatio);
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImageFile(file);
      const imageUrl = URL.createObjectURL(file);
      setImageSrc(imageUrl);
      setPreviewFeatured(null);
    } else {
      alert("Please select a valid image file.");
      e.target.value = "";
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const moveImage = (direction) => {
    const moveDistance = 0.05; // Adjust this value to control the move speed
    setCrop((prevCrop) => ({
      ...prevCrop,
      y: prevCrop.y + (direction === "up" ? -moveDistance : moveDistance),
    }));
  };

  const cropAndSaveImage = async () => {
    if (!imageSrc || !croppedAreaPixels) return;

    const canvas = document.createElement("canvas");
    const image = await loadImage(imageSrc);

    const ctx = canvas.getContext("2d");

    const { width, height } = croppedAreaPixels;
    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      width,
      height
    );

    canvas.toBlob((blob) => {
      if (blob) {
        const croppedImageUrl = URL.createObjectURL(blob);

        const resizedFile = new File([blob], imageFile.name, { type: imageFile.type });
        setSelectedFeaturedImage(resizedFile);

        setPreviewFeatured(croppedImageUrl);
        setImageSrc(null);
      }
    }, imageFile.type);
  };

  const loadImage = (url) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = url;
    });

  return (
    <>
      {isEdit ? (
        <div className="image-upload-container">
          <div className="image-upload-wrapper">
            <input
              id="file-input"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <div className="image-preview">
              {imageSrc ? (
                <div className="cropper-container">
                  <Cropper
                    image={imageSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspect} // Fixed aspect ratio 1200x420
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                  />
                  <button className="custom-crop-button" onClick={cropAndSaveImage}>
                    Save and Crop
                  </button>
                </div>
              ) : previewFeatured ? (
                <img src={previewFeatured?.includes("blob") ? previewFeatured : `${API.WEBSITE_URL}/${previewFeatured}`} alt="Selected" className="image-preview-selected" />
              ) : (
                <div className="image-placeholder">
                  <FaCamera size={40} />
                </div>
              )}
              {!imageSrc && (
                <div className="submit-row">
                  <div
                    className="camera-overlay"
                    onClick={() => document.getElementById("file-input").click()}
                  >
                    <FaCamera size={20} />
                  </div>
                  {selectedFeaturedImage && (
                    <button
                      className="custom-button"
                      onClick={handleGenerateAndShareClick}
                      disabled={!selectedFeaturedImage}
                    >
                      Upload
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="image-upload-container">
          <div className="image-upload-wrapper">
            <div className="image-preview">
              {previewFeatured ? (
                <img src={`${API.WEBSITE_URL}/${previewFeatured}`} alt="Selected" className="image-preview-selected" />
              ) : (
                <div className="image-placeholder">
                  <FaCamera size={40} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LinkTreeCustomerFeaturedProfile;
