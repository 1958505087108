import React, { useState } from 'react';
import './css/ReportWithPageViewCount.css';
import PageViewsCountChart from './chart/PageViewsCountChart';
import DateFilter from './DateFilter';

const ReportWithPageViewCount = ({ reportWithPageViewCount }) => {
  const [filteredData, setFilteredData] = useState(reportWithPageViewCount);


  return (
      <>
        {reportWithPageViewCount && reportWithPageViewCount?.length > 0 && <div className="event-count-table card-container">
          <h6 className="text-left text-background">*Below graphs show data that is atleast 24 hours old.</h6>
          <div className="filter-flex">
            <h4>Views by <b>Page title and screen name</b> <small>(Display activity reports from the start date up to 24 hours prior.)</small></h4>
            <DateFilter data={reportWithPageViewCount} onFilter={setFilteredData} />
          </div>
          <PageViewsCountChart data={filteredData} />
          {/* <div className="top-view">
            {reportWithPageViewCount?.[0] && (
              <div>
                <h3>
                  #1 {reportWithPageViewCount[0]?.dimensionValues[0]?.value}
                </h3>
                <div className="view-count">
                  <span className="view-number">{reportWithPageViewCount[0]?.metricValues[0]?.value}</span>
                  <span className="view-percentage">100%</span>
                </div>
                <div className="view-chart">
                  <div className="bar" style={{ height: '60%' }}></div>
                  <div className="bar" style={{ height: '30%' }}></div>
                </div>
              </div>
            )}
          </div> */}
          {/* <table className="center-table">
            <thead>
              <tr>
                <th>Page Title and Screen Name</th>
                <th>Views</th>
              </tr>
            </thead>
            {reportWithPageViewCount && reportWithPageViewCount?.length > 0 ? (
              <tbody>
                {reportWithPageViewCount?.map((row, index) => (
                  <tr key={index}>
                    <td>{row?.dimensionValues[0]?.value}</td>
                    <td>{row?.metricValues[0]?.value}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="2" className="no-record">No Record Found!</td>
                </tr>
              </tbody>
            )}
          </table> */}
          {/* <div className="pagination">1 - {reportWithPageViewCount?.length} of {reportWithPageViewCount?.length}</div> */}
        </div>}
      </>
  );
};

export default ReportWithPageViewCount;