import React, { useEffect, useState } from 'react';
import logo from '../../images/logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { reactLocalStorage } from "reactjs-localstorage";
import GoogleTranslateWithCustomTranslations from './GoogleTranslateWithCustomTranslations';

function Header({ children }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLogin, setIsLogin] = useState(false);

    useEffect(() => {
        if(
            location.pathname == '/home' || 
            location.pathname == '/create' || 
            location.pathname == '/dashboard' || 
            location.pathname == '/links' || 
            location.pathname == '/links-tree' || 
            location.pathname == '/pricing' || 
            location.pathname == '/account-status' || 
            location.pathname == '/privacy-policy' || 
            location.pathname == '/terms' || 
            location.pathname == '/pricing' || 
            location.pathname.includes('/links-tree/') || 
            location.pathname.includes('/analytics/') || 
            location.pathname.includes('/share/')  || 
            location.pathname.includes('/share-tree/')  || 
            location.pathname.includes('/edit/') 
        ) {
            getLocalStorageInfo();
        }
    }, [location]);

    const getLocalStorageInfo = async () => {
        try {
            let localData = reactLocalStorage.getObject("user");
            if (Object.keys(localData).length === 0 && localData.constructor === Object) {
                setIsLogin(false)
                // navigate("/login");
            } else {
                setIsLogin(true)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const logout = () => {
        reactLocalStorage.remove("user");
        window.location.href = "/login";
    }

    // useEffect(() => {
    //     const loadGoogleTranslate = () => {
    //         if (!document.getElementById('google-translate-script')) {
    //             const script = document.createElement('script');
    //             script.id = 'google-translate-script';
    //             script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    //             script.async = true;
    //             document.body.appendChild(script);
    //         } else {
    //             console.log('Google Translate script already loaded');
    //             if (window.google) {
    //                 window.googleTranslateElementInit();
    //             }
    //         }
    //     };

    //     window.googleTranslateElementInit = () => {
    //         new window.google.translate.TranslateElement(
    //             {
    //                 pageLanguage: 'de',
    //                 includedLanguages: 'en,nl,fr',
    //                 layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL,
    //             },
    //             'google_translate_element'
    //         );
    //     };

    //     loadGoogleTranslate();
    // }, []);

    return (
        <React.Fragment>
            <header className="header header-inline">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <a className="navbar-brand" href={isLogin ? "/home" : "/"}>
                            <img src={logo} alt="logo" style={{ height: '40px' }} />
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ms-auto">
                                {isLogin ? (
                                    <>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/home" translate="no">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/pricing">Pricing</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/dashboard">QR Codes Dashboard</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/links">Links Tree Dashboard</a>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a
                                                className="nav-link dropdown-toggle"
                                                href="#"
                                                id="navbarDropdown"
                                                role="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                                My Profile
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                <li>
                                                    <a className="dropdown-item" href="/account-status">Account Status</a>
                                                </li>
                                                <li>
                                                    <hr className="dropdown-divider" />
                                                </li>
                                                <li>
                                                    <a
                                                        className="dropdown-item"
                                                        href="javascript:void(0)"
                                                        onClick={logout}
                                                    >
                                                        Logout
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <GoogleTranslateWithCustomTranslations />
                                    </>
                                ) : (
                                    <>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/" translate="no">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/pricing">Pricing</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/login">Login</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="/register">Register</a>
                                        </li>
                                        <GoogleTranslateWithCustomTranslations />
                                    </>
                                )}
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            {children}
        </React.Fragment>
    );
}

export default Header;
