import React from "react";
import "./style.css";

const PricingCard = () => {
  return (
    <div className="pricing-section">
      <h1 className="title" translate="no">Maandelijks abonnement</h1>
      <div className="plans-container">
        {/* Basic Plan */}
        <div className="plan-card basic-plan">
          <h2 className="plan-header" translate="no">Basispakket</h2>
          <p className="price" translate="no">€0</p>
          <ul className="features-list" translate="no">
            <li>1 QR Code</li><hr className="hr-line" />
            <li>Live Monitoring</li><hr className="hr-line" />
            <li>Statistieken</li><hr className="hr-line" />
            <li>1 Account</li><hr className="hr-line" />
            <li>Geen - QR Links Boom</li>
          </ul>
          <button 
            className="plan-button basic-button" 
            translate="no" 
            onClick={() => {
                window.location.href = "/register";
            }}>Registreren</button>
        </div>

        {/* Pro Plan */}
        <div className="plan-card pro-plan">
          <h2 className="plan-header" translate="no">Pro</h2>
          <p className="price" translate="no">Pakket op maat</p>
          <ul className="features-list" translate="no">
            <li>Onbeperkt QR Code</li><hr className="hr-line" />
            <li>Live Monitoring</li><hr className="hr-line" />
            <li>Statistieken + History</li><hr className="hr-line" />
            <li>Support</li><hr className="hr-line" />
            <li>Onbeperkt Accounts</li><hr className="hr-line" />
            <li>Onbeperkt QR Links Boom</li>
          </ul>
          <a href="#estimate">
            <button className="plan-button pro-button" translate="no">Vraag offertes aan</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
