const API_DEQRGIDS_ENDPOINT = 'https://www.deqrgids.nl/api'; // Production
// const API_DEQRGIDS_ENDPOINT = 'http://212.227.151.163:4002/api'; // Staging - OLD
// const API_DEQRGIDS_ENDPOINT = 'http://87.106.107.220/api'; // Staging - NEW
// const API_DEQRGIDS_ENDPOINT = 'http://localhost:4000/api'; // Local server

const WEBSITE_URL = 'https://www.deqrgids.nl'; // Production
// const WEBSITE_URL = 'http://212.227.151.163:4002'; // Staging - OLD
// const WEBSITE_URL = 'http://87.106.107.220'; // Staging - NEW
// const WEBSITE_URL = 'http://localhost:4000'; // Local server

const TOKEN = "mijnqrcode_qwerty";

function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
}

function toTitleCaseAndUnderScore(str) {
    let updatedStr = str.replace("_", " ");
    return updatedStr.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
}

const API = {
    API_DEQRGIDS_ENDPOINT: API_DEQRGIDS_ENDPOINT,
    WEBSITE_URL: WEBSITE_URL,
    TOKEN: TOKEN,
    toTitleCase: toTitleCase,
    toTitleCaseAndUnderScore: toTitleCaseAndUnderScore
}

export default API;