import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import ReportWithPageViewCount from '../components/ReportWithPageViewCount';
import BatchReport from '../components/BatchReport';
import RealtimeReport from '../components/RealtimeReport';
import ReportWithEventCountByEventName from '../components/ReportWithEventCountByEventName';
import Header from '../components/Header';
import { reactLocalStorage } from 'reactjs-localstorage';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import ReportWithTechOverview from '../components/ReportWithTechOverview';
import ReportWithLeadOverview from '../components/ReportWithLeadOverview';
import ReportWithPlatformOverview from '../components/ReportWithPlatformOverview';
import ReportWithLanguageOverview from '../components/ReportWithLanguageOverview';

const Analytics = () => {
  const { qrcodeType, qrcodeId } = useParams();
  const [userInfo, setUserInfo] = useState(null);
  const [realtimeReport, setRealtimeReport] = useState(null);
  const [batchReport, setBatchReport] = useState(null);
  const [reportWithPageViewCount, setReportWithPageViewCount] = useState(null);
  const [reportWithEventCountByEventName, setReportWithEventCountByEventName] = useState(null);
  const [reportWithTechOverview, setReportWithTechOverview] = useState(null);
  const [reportWithPlatformOverview, setReportWithPlatformOverview] = useState(null);
  const [reportWithLeadOverview, setReportWithLeadOverview] = useState(null);
  const [reportWithLanguageOverview, setReportWithLanguageOverview] = useState(null);

  useEffect(() => {
    getLocalStorageInfo();
  }, []);

  const getLocalStorageInfo = async () => {
    try {
      let localData = reactLocalStorage.getObject("user");
      if (Object.keys(localData).length != 0) {
        setUserInfo(localData);
        fetchRealtimeReport();
        fetchBatchReport();
        fetchReportWithPageViewCount();
        fetchReportWithEventCountByEventName();
        fetchReportWithTechOverview();
        fetchReportWithPlatformOverview();
        fetchReportWithLeadOverview();
        fetchReportWithLanguageOverview();
      }
    } catch (e) {
      console.log(e);
    }
  }

  const fetchRealtimeReport = async () => {
    let params = { 
      eventPath: qrcodeType == "linktree" ? `/share-tree/${qrcodeId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${qrcodeId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getRealtimeReport";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        const filteredData = response?.data?.filter(item => item !== null);
        setRealtimeReport(filteredData);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchBatchReport = async () => {
    let params = { 
      eventPath: qrcodeType == "linktree" ? `/share-tree/${qrcodeId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${qrcodeId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getBatchReport";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        const filteredData = response?.data?.filter(item => item !== null);
        setBatchReport(filteredData);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchReportWithPageViewCount = async () => {
    let params = { 
      eventPath: qrcodeType == "linktree" ? `/share-tree/${qrcodeId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${qrcodeId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getReportWithPageViewCount";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        setReportWithPageViewCount(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchReportWithEventCountByEventName = () => {
    let params = {
      eventPath: qrcodeType == "linktree" ? `/share-tree/${qrcodeId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${qrcodeId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getReportWithEventCountByEventName";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        setReportWithEventCountByEventName(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchReportWithTechOverview = () => {
    let params = {
      eventPath: qrcodeType == "linktree" ? `/share-tree/${qrcodeId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${qrcodeId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getReportWithTechOverview";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        setReportWithTechOverview(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchReportWithPlatformOverview = () => {
    let params = {
      eventPath: qrcodeType == "linktree" ? `/share-tree/${qrcodeId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${qrcodeId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getReportWithPlatformOverview";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        setReportWithPlatformOverview(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchReportWithLanguageOverview = () => {
    let params = {
      eventPath: qrcodeType == "linktree" ? `/share-tree/${qrcodeId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${qrcodeId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getReportWithLanguageOverview";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        setReportWithLanguageOverview(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  const fetchReportWithLeadOverview = () => {
    let params = {
      eventPath: qrcodeType == "linktree" ? `/share-tree/${qrcodeId}` : `/redirect/${qrcodeId}`,
      unifiedScreenName: qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${qrcodeId}` : `Share QRCode Page - QRCode ${qrcodeId}`
    }
    const endpoint = "/api/getReportWithLeadOverview";
    axios.post(endpoint, params, { headers: { 'Content-Type': 'application/json' } })
      .then((response) => {
        setReportWithLeadOverview(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
      });
  };

  return (
    <Header>
      <div className="layout-center">
        <h1>VISITOR MONITORING REPORT</h1>
        {
          <h4>{qrcodeType == "linktree" ? `Share Tree Page - Link Tree ${qrcodeId}` : `Share QRCode Page - QRCode ${qrcodeId}`}</h4>
        }
        <hr />
        <div className="chat-row">
          {(realtimeReport && realtimeReport.length > 0) && <RealtimeReport realtimeReport={realtimeReport} />}

          {(batchReport && batchReport.length > 0) && <BatchReport batchReport={batchReport} />}
        </div>

        <div className="midium-vertical-space"></div>
        <ReportWithPageViewCount reportWithPageViewCount={reportWithPageViewCount} />

        <div className="midium-vertical-space"></div>
        <ReportWithEventCountByEventName reportWithEventCountByEventName={reportWithEventCountByEventName} />

        {(reportWithTechOverview || reportWithPlatformOverview) && <>
          <div className="midium-vertical-space"></div>
          <div className="row card-container">
            <div className="col-md-8">
              <ReportWithTechOverview reportWithTechOverview={reportWithTechOverview} />
            </div>
            <div className="col-md-4">
              <ReportWithPlatformOverview reportWithPlatformOverview={reportWithPlatformOverview} />
            </div>
          </div>
        </>}

        <div className="midium-vertical-space"></div>
        <ReportWithLanguageOverview reportWithLanguageOverview={reportWithLanguageOverview} />

        <div className="midium-vertical-space"></div>
        <ReportWithLeadOverview reportWithLeadOverview={reportWithLeadOverview} />

      </div>
      <Footer />
    </Header>
  );
};

export default Analytics;