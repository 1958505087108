import React, { useEffect, useRef, useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { checkIsNull, decodeUrl } from '../utils';
import Header from '../components/Header';
import API from '../config/env';
import Footer from '../components/Footer';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

const QRCodeEdit = () => {
    const {state} = useLocation();
    const { id, qrcode_url, qrcode_type, qrcode_logo, qrcode_color } = state;

    const { qrcodeId } = useParams();
    const decodedUrl = decodeUrl(qrcodeId);
    const [isLoading, setIsLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [qrCodeUrl, setQRCodeUrl] = useState(qrcode_url ? qrcode_url : decodedUrl);
    const navigate = useNavigate();
    const svgRef = useRef(null);

    const [isPreview, setIsPreview] = useState(checkIsNull(qrcode_logo) ? true : false);
    const [logo, setLogo] = useState(null);
    const [activeTab, setActiveTab] = useState("details");

    const tabs = [
        { name: "Details", id: "details", enabled: true },
        { name: "Logo", id: "logo", enabled: true },
        { name: "Color", id: "color", enabled: true },
    ];
    const [selectedColor, setSelectedColor] = useState(qrcode_color ? qrcode_color : "#000000");
    const colors = [
        "#FF5733", "#33FF57", "#3357FF", "#F1C40F", "#8E44AD",
        "#1ABC9C", "#E74C3C", "#3498DB", "#2ECC71", "#34495E",
        "#FFFFFF", "#000000"
    ];

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    const handleCustomColorChange = (e) => {
        setSelectedColor(e.target.value);
    };

    useEffect(() => {
        getLocalStorageInfo();
    }, []);

    const getLocalStorageInfo = async () => {
        try {
            let localData = reactLocalStorage.getObject("user");
            if (Object.keys(localData).length != 0) {
                setUserInfo(localData)
                setIsLoading(false);
            }
        } catch (e) {
            console.log(e);
        }
    }

    // Handlers
    // const handleLogoChange = (e) => setLogo(e.target.files[0]);
    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        if (file && file?.type?.startsWith("image/")) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = document.createElement("canvas");
                    const ctx = canvas.getContext("2d");
    
                    canvas.width = 256;
                    canvas.height = 256;
    
                    // Calculate scaling and position
                    const scale = Math.min(canvas.width / img.width, canvas.height / img.height);
                    const x = (canvas.width - img.width * scale) / 2; // Center horizontally
                    const y = (canvas.height - img.height * scale) / 2; // Center vertically
    
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
    
                    // Convert canvas to a Blob or data URL
                    canvas.toBlob((blob) => {
                        const resizedFile = new File([blob], file.name, { type: file.type });
                        setLogo(resizedFile);
                    }, file.type);
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        } else {
            alert("Please select a valid image file.");
            e.target.value = ""; // Clear the input
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (qrCodeUrl) {
            setIsLoading(true);
            
            const formData = new FormData();
            formData.append('id', id);
            formData.append('user_id', userInfo.id);
            formData.append('qrcode_id', qrcodeId);
            formData.append('unique_id', userInfo?.unique_id);
            formData.append('qrcode_url', qrCodeUrl);
            formData.append('logo', logo);
            formData.append('qrcode_color', selectedColor);

            fetch('/api/updateQRCodes', {
                method: 'POST',
                body: formData,
            }).then((response) => {
                toast.success("QRCode Update Successfully!!", {
                    position: "top-right",
                    autoClose: 2000
                });
                setIsLoading(false);
                navigate(`/share/${qrcodeId}`);
            }).catch((error) => {
                setIsLoading(false);
                console.log("error", error);
                toast.error("Something went wrong try again later!", {
                    position: "top-right",
                    autoClose: 2000
                });
            });
        } else {
            toast.error("Please Enter QRCode URL!", {
                position: "top-right",
                autoClose: 6000
            });
        }
    };


    return (
        <Header>
            <div className="create-center">
                {isLoading && <div class="whole-loading">Loading&#8230;</div>}
                <div className="create-app-center pt-5">
                    <form onSubmit={handleSubmit} className="form-creation pt-5">
                        {/* Tabs Navigation */}
                        <h1>Create QR Code</h1>
                        <div style={{ display: "flex" }}>
                            {tabs.map((tab) => (
                                <button
                                    type='button'
                                    key={tab.id}
                                    onClick={() => setActiveTab(tab.id)}
                                    style={{
                                        padding: "10px 20px",
                                        cursor: "pointer",
                                        backgroundColor: activeTab === tab.id ? "#007bff" : "rgb(69 106 167 / 60%)",
                                        color: "#fff",
                                        border: "none",
                                        borderRadius: "0px"
                                    }}
                                >
                                    {tab.name}
                                </button>
                            ))}
                        </div>

                        {/* Tab Content */}
                        <div style={{ padding: "20px", border: "1px solid #ccc", borderRadius: "5px" }}>
                            {activeTab === "details" && (
                                <div>
                                    <label>Add URL: </label>
                                    <input
                                        type="text"
                                        placeholder="Enter URL"
                                        value={qrCodeUrl}
                                        maxLength={250}
                                        className={`mb-5 inputBox ${qrcode_type == "linktree" && 'disabled'}`}
                                        onChange={(e) => {
                                            setQRCodeUrl(e.target.value)
                                        }}
                                        readOnly={qrcode_type == "linktree" ? true : false}
                                    />
                                    <p className='red-color'><small>Maximum 250 characters allowed!</small></p>
                                </div>
                            )}

                            {activeTab === "logo" && (
                                <div>
                                    <label>Upload Logo: </label>
                                    <input className="logoInputBox" type="file" accept="image/*"  onChange={handleLogoChange} />
                                    {isPreview ? <p>Selected File: {qrcode_logo?.split('/')?.pop()}</p> : logo ? <p>Selected File: {logo?.name}</p> : ""}
                                </div>
                            )}

                            {activeTab === "color" && (
                                <div>
                                    <label>Set Color Hex Code: </label>
                                    <div className="color-picker">
                                        <div className="color-picker-inline">
                                            {colors.map((color, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => handleColorClick(color)}
                                                    className="color-circle"
                                                    style={{
                                                        backgroundColor: color,
                                                        border: selectedColor === color ? "3px solid black" : "2px solid #ccc",
                                                    }}
                                                />
                                            ))}
                                        </div>
                                        <div style={{ textAlign: "center", display: "flex", marginTop: "24px" }}>
                                            <input
                                                type="color"
                                                value={selectedColor}
                                                onChange={handleCustomColorChange}
                                                className="color-select"
                                            />
                                            <input
                                                type="text"
                                                value={selectedColor}
                                                onChange={handleCustomColorChange}
                                                className="color-input"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="mt-5 mb-5" ref={svgRef}>
                            {decodedUrl && <QRCodeSVG
                                value={decodedUrl}
                                size={256}
                                imageSettings={{
                                    src: isPreview ? `${API.WEBSITE_URL}/${qrcode_logo}` : logo ? URL.createObjectURL(logo) : null,
                                    excavate: isPreview ? true : logo ? true : false,
                                    height: isPreview ? 40 : logo ? 40 : 0,
                                    width: isPreview ? 40 : logo ? 40 : 0,
                                }}
                                fgColor={selectedColor ? selectedColor : "#000"}
                            />}
                        </div>

                        <button type="submit" className={`mt-5 mb-5 btn`}>Update QRCode</button>
                    </form>
                </div>
                <div class="demo-request col-md-12">
                    <span class="tabContent">
                        <p><span class="splunk2-eyebrow">PRICING PLANS</span></p>
                        <h2><span class="splunk2-h2">Choose a plan based on your business drivers</span></h2>
                        <p><span class="splunk-body">Work with your data, your way. With a variety of data models to choose from, you can find the approach that works best for you.</span></p>
                    </span>
                    <div class="splunk-button base-component parbase">
                        <a href="/pricing#estimate">
                            <button type="button" className={`mt-5 mb-5 btn estimate`}>Get Estimates & Demo</button>
                        </a>
                    </div>
                </div>
            </div>
            <Footer />
        </Header>
    );
};

export default QRCodeEdit;
