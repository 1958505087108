import React, { useEffect, useState } from 'react';
import '../styles/LinkTree.css';
import { useParams } from 'react-router-dom';
import { checkIsNull, formatURL, getLogoByTitle } from '../utils';
import Header from '../components/Header';
import axios from 'axios';
import ReactGA from 'react-ga4';
import API from '../config/env';
import Footer from '../components/Footer';
import LinkTreeCustomerProfile from '../components/LinkTreeCustomerProfile';
import LinkTreeCustomerFeaturedProfile from '../components/LinkTreeCustomerFeaturedProfile';

function ShareLinkTree() {
    const { qrcodeId } = useParams();
    const [items, setItems] = useState([]);
    const [QRCodeInfo, setQRCodeInfo] = useState(null);

    useEffect(() => {
        // Log page view with qrcodeId
        ReactGA.send({
            hitType: 'pageview',
            page: `/share-tree/${qrcodeId}`,
            title: `Share Tree Page - Link Tree ${qrcodeId}`,
        });
    }, [qrcodeId]);

    useEffect(() => {
        if (qrcodeId) {
            getQRCodeDetailsForLinksTree()
        }
    }, [qrcodeId]);

    const getQRCodeDetailsForLinksTree = async () => {
        try {
            const getQRCodeDetailsEndpoint = `/api/getQRCodeDetailsForLinksTree/${qrcodeId}`;
            axios.get(getQRCodeDetailsEndpoint, { headers: { 'Content-Type': 'application/json' } })
                .then((response) => {
                    if (response.data.status) {
                        setQRCodeInfo(response.data.result[0]);
                        getShareLinkTree(response.data.result[0]?.qrcode_id);
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const getShareLinkTree = async (uniqueId) => {
        try {
            const getShareLinkTreeEndpoint = `/api/getShareLinkTree/${uniqueId}`;
            axios.get(getShareLinkTreeEndpoint, { headers: { 'Content-Type': 'application/json' } })
                .then((response) => {
                    if (response.data.status) {
                        setItems(response.data.result);
                    }
                })
                .catch(function (error) {
                    console.log("error", error);
                });
        } catch (e) {
            console.log(e);
        }
    };

    const shareNowLinkTree = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share - get business up with DeQRGids',
                    text: 'Generate a custom QR code instantly to share any link and download your unique QR code to share online.',
                    url: `${API.WEBSITE_URL}/share-tree/${qrcodeId}`,
                });
                console.log("Image shared successfully");
            } catch (error) {
                console.error("Error sharing:", error);
            }
        } else if (navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(`${API.WEBSITE_URL}/share-tree/${qrcodeId}`);
                alert("URL copied to clipboard! You can now share it manually.");
            } catch (error) {
                const shareUrl = `${API.WEBSITE_URL}/share-tree/${qrcodeId}`;
                alert(`Sharing is not supported in your browser. Please copy the URL to share: ${shareUrl}`);
            }
        } else {
            const shareUrl = `${API.WEBSITE_URL}/share-tree/${qrcodeId}`;
            alert(`Sharing is not supported in your browser. Please copy the URL to share: ${shareUrl}`);
        }
    }

    const Timeline = ({ items }) => {
        return (
            <div className={checkIsNull(QRCodeInfo?.featured_logo) ? "timeline-container" : "share-timeline-container"}>
                <div className="link-tree-customer-profile">
                    <LinkTreeCustomerProfile preview={QRCodeInfo?.qrcode_logo} isEdit={false} />
                </div>
                <div className="timeline">
                    {items.map((item, index) => (
                        <div key={index} className={`timeline-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                            <div className="timeline-content">
                                <a href={(item?.logo || item?.title) == "WhatsApp" ? `https://wa.me/${item.url.trim()}` :
                                    (item?.logo || item?.title) == "Contact" ? `tel:${item.url.trim()}` : formatURL(item.url.trim())}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <div className="timeline-items">
                                        <div className="circle">{getLogoByTitle((item?.logo || item?.title)) != null ? <img src={getLogoByTitle((item?.logo || item?.title))} alt={item?.title} className='circle-logo' /> : index + 1}</div>
                                        <div className="item-content">
                                            <h3 translate="no">{item.title}</h3>
                                            <p translate="no">{item.description}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <Header>
            <div className="linkTree layout-center">
                {(QRCodeInfo && QRCodeInfo != null) ? <>
                    {checkIsNull(QRCodeInfo?.featured_logo) && <div className="link-tree-customer-featured-profile">
                        <LinkTreeCustomerFeaturedProfile
                            previewFeatured={QRCodeInfo?.featured_logo}
                            isEdit={false}
                        />
                    </div>}
                    <Timeline items={items} />
                    <button className="floating-button share-link-float" onClick={() => shareNowLinkTree()}>Share</button>
                </> : <div className="mt-3 text-center">
                    <h1>Links Tree Loading...</h1>
                </div>}
            </div>
            <Footer />
        </Header>
    );
}

export default ShareLinkTree;
