import React from "react";

// Helper function to calculate total counts by language
const calculateLanguageCounts = (data) => {
    const languageCounts = {};

    data.forEach(item => {
        const language = item.dimensionValues[2].value; // Language name
        const eventCount = parseInt(item.metricValues[0].value, 10); // Event count

        if (languageCounts[language]) {
            languageCounts[language] += eventCount;
        } else {
            languageCounts[language] = eventCount;
        }
    });

    return Object.entries(languageCounts).map(([language, eventCount]) => ({ language, eventCount }));
};

const LanguageOverviewChartTable = ({ data }) => {
    if (!data || !Array.isArray(data)) {
        return <div>No data available to display the chart.</div>;
    }
    const languageData = calculateLanguageCounts(data);

    return (
        <div className="table-view-container mt-4">
            <table style={{ width: "94%", borderCollapse: "collapse", margin: "0 auto" }}>
                <thead>
                    <tr>
                        <th style={{ border: "1px solid #ccc", padding: "8px" }}>Language</th>
                        <th style={{ border: "1px solid #ccc", padding: "8px" }}>Total Event Count</th>
                    </tr>
                </thead>
                <tbody>
                    {languageData.map(({ language, eventCount }, index) => (
                        <tr key={index}>
                            <td style={{ border: "1px solid #ccc", padding: "8px" }}><b>{language}</b></td>
                            <td style={{ border: "1px solid #ccc", padding: "8px" }}>{eventCount}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default LanguageOverviewChartTable;
