import React, { useEffect, useRef, useState } from 'react';
import NotFound from '../images/notfound.png';
import './Dashboard.css';
import Header from '../components/Header';
import { reactLocalStorage } from 'reactjs-localstorage';
import axios from 'axios';
import Footer from '../components/Footer';
import LinksTreeTable from '../components/LinksTreeTable';
import { useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import { createUniqueId, encodeUrl } from '../utils';
import API from '../config/env';
import { toast } from 'react-toastify';

const ParentLinkTree = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [allLinksTree, setAllLinksTree] = useState([]);

  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [qrCodeImage, setQRCodeImage] = useState('');
  const svgRef = useRef(null);

  useEffect(() => {
    getLocalStorageInfo();
  }, []);

  const getLocalStorageInfo = async () => {
    try {
      let localData = reactLocalStorage.getObject("user");
      if (Object.keys(localData).length != 0) {
        setUserInfo(localData);
        let uniqueLinkTreeId = createUniqueId(localData.name);
        const qrCodeUrl = `${API.WEBSITE_URL}/share-tree/${uniqueLinkTreeId}`;
        setQRCodeUrl(qrCodeUrl);
        getAllLinksTree(localData.id);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const getAllLinksTree = async (user_id) => {
    try {
      setIsLoading(true);
      const getAllLinksTreeEndpoint = `/api/getAllLinksTree/${user_id}`;
      axios.get(getAllLinksTreeEndpoint, { headers: { 'Content-Type': 'application/json' } })
        .then((response) => {
          if (response.data.status) {
            setAllLinksTree(response?.data?.result);
          }
          setIsLoading(false);
        })
        .catch(function (error) {
          console.log("error", error);
          setIsLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const createNewLinksTree = () => {
    setIsLoading(true);
    try {

      const svg = svgRef.current.querySelector('svg');
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();

      img.onload = async function () {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL('image/png');
        setQRCodeImage(pngFile);

      };

      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      toast.error("Something went wrong try again later!", {
        position: "top-right",
        autoClose: 2000
      });
    }
  };

  useEffect(() => {
    if (qrCodeImage) {
      setIsLoading(true);
      const byteString = atob(qrCodeImage.split(',')[1]);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([uint8Array], { type: 'image/png' });
      const file = new File([blob], 'qrcode.png', { type: 'image/png' });

      const formData = new FormData();
      formData.append('user_id', userInfo?.id);
      formData.append('unique_id', userInfo?.unique_id);
      formData.append('qrcode_url', qrCodeUrl);
      formData.append('image', file);

      const qrcodeId = encodeUrl(qrCodeUrl);
      formData.append('qrcode_id', qrcodeId);

      formData.append('logo_url', null);
      formData.append('qrcode_color', '#000');
      formData.append('qrcode_type', "linktree");

      fetch('/api/addQRCodes', {
        method: 'POST',
        body: formData,
      }).then((response) => response.json())
      .then((response) => {
        if(response.status) {
          setTimeout(() => {
            setIsLoading(false);
            navigate(`/links-tree/${qrcodeId}`, { 
              state: { 
                id: response.result.id, 
                qrcode_url: qrCodeUrl, 
                qrcode_type: "linktree", 
                qrcode_logo: null, 
                qrcode_color: '#000' 
              } 
            });
            window.location.reload();
          }, 1000)
        } else {
          toast.error(response.message || "Something went wrong try again later!", {
            position: "top-right",
            autoClose: 2000
          });
        }
      }).catch((error) => {
        setIsLoading(false);
        console.log("error", error);
        toast.error("Something went wrong try again later!", {
          position: "top-right",
          autoClose: 2000
        });
      });
    }
  }, [qrCodeImage])

  return (
    <Header>
      <div className="layout-center">
        <h1 className="mt-5"> Links Tree </h1>
        <h4>Add your own social media and other URL</h4>
        <button className="parent-link-button" onClick={createNewLinksTree}>Create New Links Tree</button>
        <div ref={svgRef} className="d-none">
          {qrCodeUrl && <QRCodeSVG
            value={qrCodeUrl}
            size={256}
            fgColor={"#000"}
          />}
        </div>
        {
          isLoading ?
            <div class="whole-loading">Loading&#8230;</div> :
            <>
              {(allLinksTree && allLinksTree?.length > 0) ? <>
                <LinksTreeTable data={allLinksTree} userInfo={userInfo} isDashboard={true} />
              </> : <>
                <div className="mt-3 text-center">
                  <img src={NotFound} className="notFound" />
                  <h4>No Links Tree Found!</h4>
                </div>
              </>}
            </>
        }
      </div>
      <Footer />
    </Header>
  );
};

export default ParentLinkTree;