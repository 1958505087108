import React from "react";
import facebook from '../../images/facebook.png';
import linkedin from '../../images/linkedin.png';
import termsConditions from '../../pdf/terms-and-conditions.pdf';
import privacy from '../../pdf/terms-and-conditions.pdf';
import './Footer.css';

function Footer() {

    return (
        <footer class="footer" id="footer">
            <div class="container-fluid">
                <div class="footer-content">
                    <div class="footer-text">
                        <p class="footer-desc">
                            <span translate="no">De QR Gids®{' '}</span> is een handelsnaam van European IT Solutions / TECHSAGE (KvK-nummer = 63149117)
                        </p>
                        <p class="copyright">Copyright 2024 © <span translate="no">{' '}De QR Gids{' '}</span> ®. Alle rechten voorbehouden.</p>
                    </div>
                    <div class="quick-links">
                        <a className="footer-link" target="_blank" href="/privacy-policy">Privacy Policy</a>
                        <a className="footer-link" target="_blank" href="/terms">Terms & Conditions</a>
                    </div>
                    <div class="social-media">
                        <a href="https://www.facebook.com/profile.php?id=61570888711363" class="social-link" target="_blank">
                            <img src={facebook} class="icon" alt="Facebook" />
                        </a>
                        <a href="https://www.linkedin.com/company/deqrgids" class="social-link" target="_blank">
                            <img src={linkedin} class="icon" alt="LinkedIn" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
