import { Logos } from "./mock";
import { format } from "date-fns";

export const checkIsNull = (param) => {
  return param != null && param !== 'null'; // Exclude null, undefined, and the string "null"
}

// Simple hash function for shorter hash values
const generateShortHash = (input) => {
  let hash = 0;
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = (hash << 5) - hash + char; // Bitwise operations for hash generation
    hash |= 0; // Convert to 32-bit integer
  }
  return Math.abs(hash).toString(); // Return as string to append in URL
};

// Function to encode URL with a shortened format
export const encodeUrl = (url) => {
  const timestamp = Date.now(); // Get current timestamp
  const hash = generateShortHash(url); // Generate a short hash for the URL
  const combined = `${timestamp}|${url}|${hash}`; // Combine components
  const base64Url = btoa(combined)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
  return `ENC-${base64Url}`; // Add prefix
};

// Function to decode the encoded URL back to the original
export const decodeUrl = (encodedUrl) => {
  if (!encodedUrl.startsWith("ENC-")) {
    console.error("Invalid encoded URL format.");
    return null;
  }

  const base64Url = encodedUrl.split("ENC-")[1];
  const base64 = base64Url
    .replace(/-/g, '+')
    .replace(/_/g, '/')
    .padEnd(base64Url.length + (4 - (base64Url.length % 4)) % 4, '=');

  try {
    const decoded = atob(base64);

    // Handle new format with delimiter
    if (decoded.includes('|')) {
      const parts = decoded.split('|');
      return parts[1]; // Extract the URL part
    }

    // Handle old format (plain URL without timestamp)
    return decoded; // Return the decoded plain URL
  } catch (error) {
    console.error("Decoding error:", error);
    return null;
  }
};



export const formatDate = (dateString) => {
    const year = dateString?.slice(0, 4);
    const month = dateString?.slice(4, 6);
    const day = dateString?.slice(6, 8);
    return `${day}-${month}-${year}`;
};


export const formatURL = (url) => {
    if (url.startsWith("https://") || url.startsWith("http://")) {
      return url;
    } else {
      return `//${url}`;
    }
}


export const getWebsiteName = (url) => {
    // Remove protocol (http, https)
    url = url.replace(/(^\w+:|^)\/\//, "");
  
    // Remove "www." if present
    if (url.startsWith("www.")) {
      url = url.slice(4);
    }
  
    // Get the domain name before the first dot
    const domainName = url.split(".")[0];
  
    return domainName;
}

export const getLogoByTitle = (title) => {
    const item = Logos.find((item) => item?.title?.toLowerCase() === title?.toLowerCase());
    return item ? item?.image : null; // Returns the image if found, otherwise null
};

// Utility function to filter data based on date range
export const applyDateFilter = (data, filter, startDate, endDate) => {
  const today = new Date();
  let filteredData = data;

  switch (filter) {
    case "today":
      filteredData = data.filter(item =>
        format(new Date(item.dimensionValues[1].value), "yyyyMMdd") === format(today, "yyyyMMdd")
      );
      break;

    case "yesterday":
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      filteredData = data.filter(item =>
        format(new Date(item.dimensionValues[1].value), "yyyyMMdd") === format(yesterday, "yyyyMMdd")
      );
      break;

    case "thisWeek":
      const startOfWeek = new Date(today);
      startOfWeek.setDate(today.getDate() - today.getDay());
      filteredData = data.filter(item => {
        const itemDate = new Date(item.dimensionValues[1].value);
        return itemDate >= startOfWeek && itemDate <= today;
      });
      break;

    case "lastWeek":
      const endOfLastWeek = new Date(today);
      endOfLastWeek.setDate(today.getDate() - today.getDay() - 1);
      const startOfLastWeek = new Date(endOfLastWeek);
      startOfLastWeek.setDate(endOfLastWeek.getDate() - 6);
      filteredData = data.filter(item => {
        const itemDate = new Date(item.dimensionValues[1].value);
        return itemDate >= startOfLastWeek && itemDate <= endOfLastWeek;
      });
      break;

    case "lastMonth":
      const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      filteredData = data.filter(item => {
        const itemDate = new Date(item.dimensionValues[1].value);
        return itemDate >= startOfLastMonth && itemDate <= endOfLastMonth;
      });
      break;

    case "custom":
      if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);
        filteredData = data.filter(item => {
          const itemDate = new Date(item.dimensionValues[1].value);
          return itemDate >= start && itemDate <= end;
        });
      }
      break;

    default:
      filteredData = data; // No filter applied
      break;
  }

  return filteredData;
};


export const createUniqueId = (name) => {
  // Convert name to lowercase, remove special characters, and replace spaces with hyphens
  const cleanName = name
    .toLowerCase()
    .replace(/[^a-z0-9\s]/g, '')    // Remove special characters
    .trim()
    .replace(/\s+/g, '-');           // Replace spaces with hyphens

  // Get the current date and time
  const now = new Date();
  const timestamp = now.getTime();  // Use milliseconds for uniqueness

  // Concatenate name slug with timestamp to form a unique slug
  return `${cleanName}-${timestamp}`;
}

export const validatePassword = (password) => {
  const minLength = 8;
  const uppercasePattern = /[A-Z]/;
  const lowercasePattern = /[a-z]/;
  const digitPattern = /\d/;
  const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

  const errors = [];

  if (password.length < minLength) {
      errors.push("Minimum 8 characters.");
  }
  if (!uppercasePattern.test(password)) {
      errors.push("At least 1 uppercase character.");
  }
  if (!lowercasePattern.test(password)) {
      errors.push("At least 1 lowercase character.");
  }
  if (!digitPattern.test(password)) {
      errors.push("At least 1 digit.");
  }
  if (!specialCharPattern.test(password)) {
      errors.push("At least 1 special character.");
  }

  if (errors.length > 0) {
      return { isValid: false, errors };
  }
  return { isValid: true };
}
