import React, { useState, useEffect } from 'react';
import './css/style.css';
import TechOverviewChart from './chart/TechOverviewChart';
import DateFilter from './DateFilter';
import TechOverviewChartTable from './chart/TechOverviewChartTable';

const ReportWithTechOverview = ({ reportWithTechOverview }) => {
  const [filteredData, setFilteredData] = useState(reportWithTechOverview);

  return (
    <>
      {reportWithTechOverview && reportWithTechOverview?.length > 0 && <div className="event-count-table">
        <h6 className="text-left text-background" >*Below graphs show data that is atleast 24 hours old.</h6>
        <div className="filter-flex">
          <h4>Report With <b>Tech Overview</b> <small>(Display activity reports from the start date up to 24 hours prior.)</small></h4>
          <DateFilter data={reportWithTechOverview} onFilter={setFilteredData} />
        </div>
        <TechOverviewChart data={filteredData} />
        <TechOverviewChartTable data={filteredData} />
      </div>}
    </>
  );
};

export default ReportWithTechOverview;